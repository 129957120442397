<template>
  <section>
    <b-overlay :show="loading">
      <div align="left">
        <b-button
          variant="outline-danger"
          :to="{ name: 'admin-pengguna-customer' }"
          class="btn-icon mb-1"
        >
          <feather-icon icon="ArrowLeftIcon" class="mr-25" />Kembali</b-button
        >
      </div>
      <b-row
        ><b-col md="12">
          <!-- <b-card> -->
          <b-tabs>
            <b-tab active>
              <!-- title -->
              <template #title>
                <feather-icon icon="UserIcon" size="18" class="mr-50" />
                <span class="font-weight-bold">Profile</span>
              </template>
              <b-card>
                <!-- form -->
                <validation-observer ref="simpleRules">
                  <b-form v-if="loaded" class="mt-2">
                    <b-row>
                      <b-col sm="6">
                        <b-form-group>
                          <label for="Nama">Nama Lengkap</label>
                          <small class="text-danger"> <b> (*)</b></small>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Nama"
                          >
                            <b-form-input
                              v-model="pengguna.name"
                              id="Nama"
                              placeholder="Nama Lengkap"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6">
                        <b-form-group>
                          <label for="username">Username</label>
                          <small class="text-danger"> <b> (*)</b></small>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Username"
                          >
                            <b-form-input
                              v-model="pengguna.username"
                              name="username"
                              placeholder="Username"
                              readonly
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6">
                        <b-form-group>
                          <label for="Email">Email</label>
                          <small class="text-danger"> <b> (*)</b></small>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Email"
                          >
                            <b-form-input
                              v-model="pengguna.email"
                              placeholder="Email"
                              name="email"
                              readonly
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6">
                        <b-form-group>
                          <label for="Nomor Telepon/Whatsapp"
                            >Nomor Telepon/Whatsapp</label
                          >
                          <small class="text-danger"> <b> (*)</b></small>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Nomor Telepon/Whatsapp"
                          >
                            <b-form-input
                              v-model="pengguna.no_telp"
                              name="no_telp"
                              placeholder="Nomor Telepon/Whatsapp"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6">
                        <b-form-group>
                          <label for="Asal Sekolah">Asal Sekolah</label>
                          <small class="text-danger"> <b> (*)</b></small>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Asal Sekolah"
                          >
                            <!-- <b-form-input
                              v-model="pengguna.asal_sekolah"
                              placeholder="Asal Sekolah"
                              name="asal_sekolah"
                              :state="errors.length > 0 ? false : null"
                            /> -->
                            <v-select
                              label="text"
                              v-model="pengguna.asal_sekolah"
                              :options="optSekolah"
                              name="asal_sekolah"
                              placeholder="Pilih"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6">
                        <b-form-group>
                          <label for="Asal Kota">Asal Kota</label>
                          <small class="text-danger"> <b> (*)</b></small>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Asal Kota"
                          >
                            <v-select
                              label="nama_kabupaten"
                              v-model="pengguna.asal_kota"
                              :options="options"
                              name="asal_sekolah"
                              placeholder="Pilih"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6">
                        <b-form-group
                          label="Tanggal Lahir"
                          label-for="tgl_lahir"
                        >
                          <flat-pickr
                            id="tgl_lahir"
                            v-model="pengguna.tgl_lahir"
                            name="tgl_lahir"
                            class="form-control"
                            :config="{
                              altInput: true,
                              altFormat: 'd-m-Y',
                              dateFormat: 'Y-m-d',
                            }"
                            placeholder="Masukkan tanggal lahir"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="6">
                        <b-form-group label="Level" label-for="level">
                          <b-form-select
                            v-model="pengguna.level_id"
                            :options="optionsLevel"
                            label="Level"
                            placeholder="Level"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group label="Nama Ayah" label-for="nama_ayah">
                          <b-form-input
                            v-model="pengguna.nama_ayah"
                            placeholder="Nama Ayah"
                            name="nama_ayah"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group
                          label="Nomer Telepon/Whatsapp Ayah"
                          label-for="no_telp_ayah"
                        >
                          <b-form-input
                            v-model="pengguna.no_telp_ayah"
                            placeholder="Nomer Telepon/Whatsapp Ayah"
                            name="no_telp_ayah"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group
                          label="Profesi Ayah"
                          label-for="profesi_ayah"
                        >
                          <b-form-input
                            v-model="pengguna.profesi_ayah"
                            placeholder="Profesi Ayah"
                            name="profesi_ayah"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group label="Nama Ibu" label-for="nama_ibu">
                          <b-form-input
                            v-model="pengguna.nama_ibu"
                            placeholder="Nama Ibu"
                            name="nama_ibu"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group
                          label="Nomer Telepon/Whatsapp Ibu"
                          label-for="no_telp_ibu"
                        >
                          <b-form-input
                            v-model="pengguna.no_telp_ibu"
                            placeholder="Nomer Telepon/Whatsapp Ibu"
                            name="no_telp_ibu"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group
                          label="Profesi Ibu"
                          label-for="profesi_ibu"
                        >
                          <b-form-input
                            v-model="pengguna.profesi_ibu"
                            placeholder="Profesi Ibu"
                            name="profesi_ibu"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="mt-2 mr-1"
                          @click.prevent="SimpanProfile"
                        >
                          Simpan
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-card>
            </b-tab>
            <b-tab>
              <!-- title -->
              <template #title>
                <feather-icon icon="LockIcon" size="18" class="mr-50" />
                <span class="font-weight-bold">Kata Sandi</span>
              </template>
              <b-card>
                <!-- form -->
                <b-form class="mt-2">
                  <b-row>
                    <b-col sm="6">
                      <b-form-group
                        label="Kata Sandi Baru"
                        label-for="kata_sandi_baru"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Kata Sandi Baru"
                          vid="kata_sandi_baru"
                          rules="required|min:8"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-input
                              id="kata_sandi_baru"
                              v-model="new_password"
                              :type="passwordFieldTypeB"
                              :state="errors.length > 0 ? false : null"
                              class="form-control-merge"
                              name="kata_sandi_baru"
                              placeholder="Masukkan Kata Sandi Baru"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                class="cursor-pointer"
                                :icon="passwordToggleIconB"
                                @click="togglePasswordVisibilityB"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6">
                      <b-form-group
                        label-for="ckata_sandi_baru"
                        label="Konfirmasi Kata Sandi Baru"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Konfirmasi Kata Sandi Baru"
                          rules="required|confirmed:kata_sandi_baru"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-input
                              id="ckata_sandi_baru"
                              v-model="cNew_password"
                              :type="passwordFieldTypeK"
                              class="form-control-merge"
                              :state="errors.length > 0 ? false : null"
                              name="ckata_sandi_baru"
                              placeholder="Konfirmasi Kata Sandi Baru"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                class="cursor-pointer"
                                :icon="passwordToggleIconK"
                                @click="togglePasswordVisibilityK"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mt-2 mr-1"
                        @click.prevent="SimpanPassword"
                      >
                        Simpan
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-tab>
          </b-tabs>
          <!-- </b-card> -->
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import id from "vee-validate/dist/locale/id.json";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import {
  BOverlay,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
// import { ValidationProvider, ValidationObserver } from "vee-validate";
// import { required } from "@validations";
// function loadLocale(code) {
//   return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
//     localize(code, locale);
//   });
// }

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      required,
      locale: "id",
      loaded: false,
      profile: [],
      pengguna: {
        nama_lengkap: null,
        asal_sekolah: "",
        asal_kota: null,
        tgl_lahir: null,
        username: "",
        email: "",
        no_telp: "",
        nama_ayah: "",
        no_telp_ayah: "",
        profesi_ayah: "",
        nama_ibu: "",
        no_telp_ibu: "",
        profesi_ibu: "",
        level_id: null,
      },
      options: [
        { value: "bandung", text: "Bandung" },
        { value: "jakarta", text: "Jakarta" },
        { value: "Surabaya", text: "Surabaya" },
      ],
      optionsLevel: [],
      optSekolah: [],
      loading: false,
      passwordFieldTypeB: "password",
      passwordFieldTypeK: "password",
      new_password: "",
      cNew_password: "",
    };
  },
  computed: {
    passwordToggleIconB() {
      return this.passwordFieldTypeB === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconK() {
      return this.passwordFieldTypeK === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    isValidPasswordForm() {
      if (
        !this.new_password ||
        this.new_password == "" ||
        !this.cNew_password ||
        this.cNew_password == ""
      ) {
        return false;
      }

      return true;
    },
  },
  watch: {},
  methods: {
    togglePasswordVisibilityB() {
      this.passwordFieldTypeB =
        this.passwordFieldTypeB === "password" ? "text" : "password";
    },
    togglePasswordVisibilityK() {
      this.passwordFieldTypeK =
        this.passwordFieldTypeK === "password" ? "text" : "password";
    },

    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    SimpanProfile() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // if (
          //   (this.pengguna.name == null && this.pengguna.no_telp == null) ||
          //   (this.pengguna.name == "" && this.pengguna.no_telp == "")
          // ) {
          //   this.pesanGagal();
          //   return false;
          // }
          let payload = {
            id: this.$route.params.id,
            name: this.pengguna.name,
            no_telp: this.pengguna.no_telp,
            birth_date: this.pengguna.tgl_lahir,
            school_origin: this.pengguna.asal_sekolah.id,
            city_id: this.pengguna.asal_kota.id,
            father_name: this.pengguna.nama_ayah,
            father_no_telp: this.pengguna.no_telp_ayah,
            father_profession: this.pengguna.profesi_ayah,
            mother_name: this.pengguna.nama_ibu,
            mother_no_telp: this.pengguna.no_telp_ibu,
            mother_profession: this.pengguna.profesi_ibu,
            level_id: this.pengguna.level_id,
          };

          this.$store
            .dispatch("pengguna/createUpdateDelete", [payload])
            .then(() => {
              // this.ModalEdit = false;
              this.getProfile();
              this.displaySuccess({
                text: "Profil berhasil diubah",
              });
              this.pengguna = {
                nama_lengkap: "",
                asal_sekolah: "",
                asal_kota: null,
                tgl_lahir: null,
                username: "",
                email: "",
                no_telp: "",
                nama_ayah: "",
                no_telp_ayah: "",
                profesi_ayah: "",
                nama_ibu: "",
                no_telp_ibu: "",
                profesi_ibu: "",
                level_id: null,
              };
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    SimpanPassword() {
      if (!this.isValidPasswordForm) {
        this.displayError({
          message: "Harap lengkapi data form reset password dengan benar",
        });
        return false;
      }

      const notMatch = this.new_password != this.cNew_password;
      if (notMatch) {
        this.displayError({
          message: "Password dan Konfirmasi Password tidak sama",
        });
        return false;
      }
      const payload = {
        id: this.$route.params.id,
        name: this.pengguna.name,
        no_telp: this.pengguna.no_telp
          ? this.pengguna.no_telp
          : "00800000000000",
        birth_date: this.pengguna.tgl_lahir,
        school_origin: this.pengguna.asal_sekolah.value,
        level_id: this.pengguna.level_id,
        password: this.new_password,
      };

      this.$store
        .dispatch("pengguna/createUpdateDelete", [payload])
        .then(() => {
          this.displaySuccess({
            text: "Kata Sandi berhasil diubah",
          });
          this.new_password = null;
          this.cNew_password = null;
          this.$router.go();
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getKabKot() {
      this.loading = true;
      this.$store
        .dispatch("kabupaten_kota/index")
        .then((res) => {
          this.loading = false;
          this.options = res.data.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },

    getProfile() {
      this.loading = true;
      this.loaded = false;
      let id = this.$route.params.id;
      this.$store
        .dispatch("pengguna/detail", id)
        .then((res) => {
          this.switchLoc();
          this.profile = res.data;
          this.pengguna.name = this.profile.name;
          this.pengguna.username = this.profile.username;
          this.pengguna.email = this.profile.email;
          this.pengguna.no_telp = this.profile.no_telp;
          this.pengguna.level_id = this.profile.level_id;
          if (this.profile.detail != null) {
            this.pengguna.tgl_lahir = this.profile.detail.birth_date;
            this.pengguna.nama_ayah = this.profile.detail.father_name;
            this.pengguna.no_telp_ayah = this.profile.detail.father_no_telp;
            this.pengguna.profesi_ayah = this.profile.detail.father_profession;
            this.pengguna.nama_ibu = this.profile.detail.mother_name;
            this.pengguna.no_telp_ibu = this.profile.detail.mother_no_telp;
            this.pengguna.profesi_ibu = this.profile.detail.mother_profession;
            this.pengguna.asal_sekolah =
              this.profile.detail.school_origin_info == null
                ? 0
                : this.profile.detail.school_origin_info.nama_sekolah;
            if (this.profile.detail.city_id) {
              this.$store
                .dispatch("kabupaten_kota/index")
                .then((res) => {
                  let kabKot = res.data.data;
                  let filter = kabKot.filter((res) => {
                    return res.id == this.profile.detail.city_id;
                  });
                  // let filter2 = this.optSekolah.filter((res) => {
                  //   return res.id == this.profile.detail.school_origin.id;
                  // });
                  // this.pengguna.asal_sekolah = filter2[0].nama_sekolah;
                  this.pengguna.asal_kota = filter[0].nama_kabupaten;
                })
                .catch((err) => {
                  this.loading = false;
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Error `,
                      icon: "XIcon",
                      variant: "danger",
                      text: err.response.data.message,
                    },
                  });
                });
            }
            // this.$refs.simpleRules.validate();
          }
          this.loaded = true;
          this.loading = false;
          // this.$refs.simpleRules.validate();
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    getDatalevel() {
      this.$store.dispatch("level/index", {}).then((response) => {
        let level = response.data.data;
        let arr = [1];
        let res = arr.map((id) => level.find((x) => x.id == id));
        res.map((item) => {
          item.value = item.id;
          item.text = item.nama_level;
        });
        this.optionsLevel = res;
      });
    },
    switchLoc() {
      // switch the locale.
      this.locale = this.locale === "id" ? "id" : "id";
      localize(this.locale, id);

      // re-validate to re-generate the messages.
      // this.$refs.simpleRules.validate();
    },
    getSekolah() {
      this.loading = true;
      const payload = {
        is_official_school: null,
      };
      this.$store
        .dispatch("mastersekolah/index", payload)
        .then((response) => {
          let optSekolah = response.data.data;
          optSekolah.map((item) => {
            item.value = item.id;
            item.text = item.nama_sekolah;
          });
          this.optSekolah = optSekolah;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
  },
  created() {},
  mounted() {
    this.getProfile();
    this.getKabKot();
    this.getDatalevel();
    this.getSekolah();
    // localize("id");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
